<template>
  <div class="divDG">
    <DxDataGrid class="dx-card wide-card" :data-source="containers" :key-expr="containerId" :hover-state-enabled="true"
      :selection="{ mode: 'single' }" :show-borders="true" :columns-auto-width="false" :column-min-width="50"
      :allow-column-resizing="true" :column-resizing-mode="dataGridColResizeMode" :allow-column-reordering="true"
      no-data-text="Loading ..." @row-click="datagridRowClick" 
      @initialized="setGridInstance" @content-ready="dataReady"
      :style="{ 'max-height': datagridHeight }">
      <!-- @selection-changed="datagridSelectionChanged" -->
      
      <!-- <DxStateStoring :enabled="true" type="localStorage" :storage-key="rep_status_container_grid_v2" /> -->
      <DxStateStoring :enabled="true" type="custom" :custom-save="saveState" />
      <!-- <DxStateStoring :enabled="true" type="custom" :custom-load="setGridState" /> -->

      <DxToolbar>
        <DxItem location="before" template="filterStatusCriticalTemplate" />
        <DxItem location="before" template="filterStatusMarginalTemplate" />
        <DxItem location="before" template="filterStatusGoodTemplate" />
        <DxItem location="before" template="filterStatusAllTemplate" />
        <DxItem location="after">
          <div class="mininote">Rows: {{ datagridRowCount }} </div>
        </DxItem>
        <DxItem location="after" template="removeFilterRecordTemplate" />
        <DxItem location="after" template="refreshRecordTemplate" />
        <!-- <DxItem location="after" template="saveLayout" /> -->
        <DxItem location="after" name="columnChooserButton" />
      </DxToolbar>

      <template #filterStatusCriticalTemplate>
        <DxButton text="❌ " hint="Filter Critical Values" @click="filterToggle('❌')" />
      </template>
      <template #filterStatusMarginalTemplate>
        <DxButton text="⚠️ " hint="Filter Marginal Values" @click="filterToggle('⚠️')" />
      </template>
      <template #filterStatusGoodTemplate>
        <DxButton text="✔️ " hint="Filter Good Values" @click="filterToggle('✔️')" />
      </template>
      <template #filterStatusAllTemplate>
        <DxButton text="All" hint="All Statuses" @click="filterToggle('')" />
      </template>
      <template #refreshRecordTemplate>
        <DxButton icon="refresh" hint="Refresh Data" @click="refreshData" />
      </template>
      <!-- <template #saveLayout>
        <DxButton icon="save" hint="Save Layout" @click="saveState" />
      </template> -->
      <template #removeFilterRecordTemplate>
        <DxButton icon="filter" hint="Clear Filters & Sorting" @click="removeFilter" />
      </template>

      <DxLoadPanel :enabled="true" />
      <DxColumnChooser :enabled="true" mode="select" />
      <DxFilterRow :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="standard" /> <!-- mode="virtual" -->
      <DxColumnFixing :enabled="true" />
      <DxPager :show-info="true" :show-page-size-selector="true" :allowed-page-sizes="[20, 50, 100]"
        :show-navigation-buttons="true" />

      <!-- <DxColumn id="stat" data-field="containerStatus" fixed="true" caption="" width="55px" alignment="left"
        :allow-resizing="false" :allow-filtering="true" :show-in-column-chooser="false" :allow-reordering="false" />
      <DxColumn id="device" data-field="serialnum" caption="Device" width="120px" :allow-filtering="true" :visible="true" />
      <DxColumn data-field="operator" caption="Operator" min-width="125" />
      <DxColumn data-field="area" caption="Area" min-width="125" />
      <DxColumn data-field="containerName" caption="Surface" :allow-reordering="true" min-width="150" />
      <DxColumn v-for="prop in properties" :key="prop" :data-field="prop" :caption="prop" alignment='left' min-width="100" :visible="true" />  -->
    </DxDataGrid>
  </div> 
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxHeaderFilter,
  DxFilterRow,
  DxScrolling,
  DxColumnChooser,
  DxToolbar,
  DxItem,
  DxPager,
  DxColumnFixing,
  DxStateStoring
} from "devextreme-vue/data-grid";
import { DxButton } from 'devextreme-vue/button';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  components: {
    DxButton,
    // DxColumn,
    DxColumnChooser,
    DxColumnFixing,
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxItem,
    DxLoadPanel,
    DxPager,
    DxScrolling,
    DxToolbar,
    DxStateStoring
  },
  created() {
    this.loadData();
  },
  mounted() {
    window.addEventListener('resize', this.setDimensions);
    this.setDimensions();
  },
  unmounted() {
    window.removeEventListener('resize', this.setDimensions);
  },
  props: ['typeId'],
  data() {
    return {
      dataGridColResizeMode: 'widget',
      dataGridInstance: null,
      datagridHeight: '40vh',
      datagridRowCount: 0,
      properties:[],
      containers:[],
      currentContainer: "",
      savedState:"",
      stateOpen: false,
      defaultState:"",
    };
  },
  computed: { },
  methods: {
    setDimensions() {
      const h = document.documentElement.clientHeight;
      this.datagridHeight= (h-210) + 'px';
    },

    dataReady(e) {
      this.datagridRowCount = this.dataGridInstance.totalCount();
    },

    setGridInstance(e) {
      this.dataGridInstance = e.component;
      this.dataGridInstance.columnOption(0, 'allowHeaderFiltering', false);
    },

    removeFilter() {
      this.dataGridInstance.clearFilter();
      this.dataGridInstance.clearSorting();
    },

    refreshData() {
      this.loadData();
    },

    async filterToggle(filterArg) {
      this.dataGridInstance.columnOption(0, 'filterValue', filterArg);    
    },

    datagridRowClick(e) {
      this.currentContainer = e.component.getSelectedRowKeys()[0];
      console.log("EMIT CLICK, CONTAINER ", this.currentContainer.containerId)
      this.$emit('select-container', this.currentContainer.containerId);
    },
    
    saveState(){
      if (this.savedState != "" && !(this.savedState.stateValue == JSON.stringify(this.dataGridInstance.state().columns)))
      {
        this.$store.dispatch('userState/addUserState', { name: 'containerStatusV2', stateValue: JSON.stringify(this.dataGridInstance.state().columns)});
      }
    },

    setGridState(){
      this.savedState = this.$store.getters["userState/getUserState"];
      if (this.savedState.stateValue != "default")
      {
        let obj = JSON.parse(this.savedState.stateValue);
        obj.sort((a, b) => b.visibleIndex - a.visibleIndex);
 
        for(let i=0;i<obj.length;i++){
          //index
          this.dataGridInstance.columnOption(obj[i].name, 'visibleIndex', obj[i].visibleIndex);  
          //visible
          this.dataGridInstance.columnOption(obj[i].name, 'visible', obj[i].visible);  
          //width
          this.dataGridInstance.columnOption(obj[i].name, 'width', (obj[i].width));
        }  
      }
    },

    async loadData(refresh = false) {
      try {
        // await this.$store.dispatch("containers/loadContainersStatusV2");
        // await this.$store.dispatch("properties/loadProperties");
        
        await Promise.all([
          this.$store.dispatch("containers/loadContainersStatusV2"),
          this.$store.dispatch("properties/loadProperties"),
          // this.$store.dispatch("userState/loadUserState", { id: 'containerStatusV2' })
        ])
        console.log()

        // this.setGridState();
        this.containers = this.$store.getters["containers/getContainersV2"];
      } catch (error) {
        this.error = error.message || "Unexpected Error.";
      }
    },
  },
};
</script>

<style scoped>
  .mininote {
    text-align: left;
    font-size: 11px;
    /* margin-top:8px; */
    /* margin-bottom:0em; */
    /* margin-left:2.5em;  */
    font-weight: 300;
    color: rgb(255, 125, 0);
  }
  .pheader {
    font-size: 12px;
    font-weight: 300;
    color: rgb(255,255,255,.4);
    margin-bottom: 16px;
    z-index: 20;
  }
  .pval {
    font-size: 16px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 10px;
    z-index: 20;
  }
  .divDG {
    /* width: 95%; */
    /* border-style: solid; */
    border-style: none;
    border-width: 1px; 
    margin: 20px;
    border-color: rgb(100, 100, 100);
    border-radius: 6px;
    /* padding: 10px; */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  }
  
</style>
