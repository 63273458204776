export default {
  async loadActiveDevices(context, payload ) {
    var token = context.rootGetters['auth/getToken']
    var bearer_token = "Bearer " + token;
    var apiUrl = context.rootGetters['config/getApiUrl']
    var url = `${apiUrl}/activedevices`;

    const response = await fetch(url, {
        method: "GET",
        headers: {
        Authorization: bearer_token,
        "Content-Type": "application/json",
        },
    });
    if (!response.ok) {
        //error, NOT ok
        const error = new Error(responseData.message || "Failed to fetch.");
        throw error;
    }
    const responseJson = await response.json();
    const responseData = [];
    
    console.log("JSON", responseJson);

    for (const key in responseJson) {
        const device = {
          deviceId: responseJson[key].deviceId,
          status: responseJson[key].status,
          imei: responseJson[key].imei,
          manufacturer: responseJson[key].manufacturer,
          usageType: responseJson[key].usageType,
          deviceType: responseJson[key].deviceType,
          containerId: responseJson[key].containerData.containerName,
          containerType: responseJson[key].containerData.containerType,
          operator: responseJson[key].containerData.operator,
          area: responseJson[key].containerData.area,
          office: responseJson[key].containerData.office,
          district: responseJson[key].containerData.district,
          containerName: responseJson[key].containerData.containerName,
          alias: responseJson[key].containerData.containerProperties.alias,
          downholeLSD: responseJson[key].containerData.containerProperties['downhole lsd'],
          product: responseJson[key].containerData.containerProperties.product,
          satellite: responseJson[key].containerData.containerProperties['satellite connection'],
          // contractId: responseJson[key].contractId,
          contractName:responseJson[key].contractName,
          contractDate:responseJson[key].contractDate,
          installDate:responseJson[key].installDate,
          latitude: responseJson[key].containerData.latitude,
          longitude: responseJson[key].containerData.longitude,
        };
        responseData.push(device);
    }
    console.log("RESPONSE DATA", responseData);

    context.commit('setActiveDevices', responseData)
  },
};